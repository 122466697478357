import { config } from '../cognitocmsapi/default_config.js'

config.devURL = `https://roadcraft.${config.devbase}`
config.prodURL = 'https://cms.roadcraft.org.au'
config.siteURL = 'https://app.roadcraft.org.au'
config.baseURL = config.devbase == '' ? config.prodURL : config.devURL

config.isEcommerce = false
config.sentryDsn = 'https://6c3d58d887f840798e04432e231a16c2@glitchtip.logger.jm1.au/22'

config.login.code_only = true
config.signUp.ask_date_of_birth = true
config.profile.edit_date_of_birth = true
config.profile.manage_addresses = true

export { config }
