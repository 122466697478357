<template>
  <div class="mx-auto max-w-lg p-4">
    <div class="py-4">
      <img class="mx-auto" src="../images/logo.png">
    </div>
    <cgn-button url="/calendar" fullwidth class="cgn-button-stylized mb-1">
      <i-heroicons-solid:calendar class="inline" />
      Calendar
    </cgn-button>
    <cgn-button url="/roster" fullwidth class="cgn-button-stylized mb-1">
      <i-heroicons-solid:calendar class="inline" />
      Roster
    </cgn-button>
    <cgn-button url="/timesheet" fullwidth class="cgn-button-stylized mb-1">
      <i-heroicons-solid:paper-airplane class="inline" />
      Timesheet
    </cgn-button>
    <cgn-button url="/links" fullwidth class="cgn-button-stylized mb-1">
      <i-heroicons-solid:link class="inline" />
      Links
    </cgn-button>
    <cgn-ios-pwa-prompt />
  </div>
</template>

<script setup lang="ts">
</script>

<route lang="yaml">
meta:
  requiresAuth: true
</route>
